import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RootModule } from './root.module';
import { environment } from './environments/environment';
import { BrowserCacheLocation, Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase, HttpXhrBackend } from '@angular/common/http';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, share, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import {
    GetIntegrationConfigurationOffice365SSOQuery,
    IntegrationConfigurationOffice365SSOResponse
} from './shared/service-proxies/service-proxies';
import { AppConsts } from './shared/AppConsts';
import { SubdomainTenancyNameFinder } from './shared/helpers/SubdomainTenancyNameFinder';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
    || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal
const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
    };
}

export function MsalInstanceFactory(): PublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: AppConsts.office365SSOClientId,
            authority: AppConsts.office365SSOAuthority,
            redirectUri: AppConsts.office365SSORedirectUri
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE,
        },
        system: {
            loggerOptions: {
                loggerCallback: (logLevel, message) => {
                    console.log(message);
                },
                piiLoggingEnabled: true
            }
        }
    });
}

if (environment.production) {
    enableProdMode();
}

const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

httpClient.request('get', AppConsts.appSettingsUrl).toPromise().then((result: any) => {

    // Se obtiene la url remota
    AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl;

    // Obtener el componente "tenancyName"
    const subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);

    const request = new GetIntegrationConfigurationOffice365SSOQuery();
    request.tenancyName = tenancyName; // 'algoria';

    const promise = httpClient.post<IntegrationConfigurationOffice365SSOResponse>(
        AppConsts.remoteServiceBaseUrl + '/api/integrationconfigurations/getconfigurationoffice365sso',
        request)
        .pipe(share()).toPromise();

    promise.then(json => {

        console.log(json);

        let authConfig: any = null;
        authConfig = json;

        AppConsts.office365SSOClientId = authConfig.azureClientId;
        AppConsts.office365SSOTenantId = authConfig.azureTenantId;
        AppConsts.office365SSORedirectUri = authConfig.redirectUri;
        AppConsts.office365SSOEnabled = authConfig.isActive;

        if (authConfig.azureSite === 'microsoft.com') {
            AppConsts.office365SSOAuthority = 'https://login.microsoftonline.com/' + authConfig.azureTenantId;
        }

        if (authConfig.azureSite === 'microsoft.us') {
            AppConsts.office365SSOAuthority = 'https://login.microsoftonline.com/' + authConfig.azureTenantId;
        }

        const providers = [
            {
                provide: MSAL_INSTANCE, useFactory: MsalInstanceFactory
            },
            {
                provide: MSAL_GUARD_CONFIG,
                useFactory: MSALGuardConfigFactory
            },
            {
                provide: MSAL_INTERCEPTOR_CONFIG,
                useFactory: MSALInterceptorConfigFactory
            },
        ];
        platformBrowserDynamic(providers)
            .bootstrapModule(RootModule)
            .catch((err) => console.error(err));
    });
});
