import { NgModule } from '@angular/core';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AuthServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.RegisterServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.OrgUnitServiceProxy,
        ApiServiceProxies.UserConfigurationServiceProxy,
        ApiServiceProxies.ChangeLogServiceProxy,
        ApiServiceProxies.MailGroupServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.ChatMessageServiceProxy,
        ApiServiceProxies.HelpServiceProxy,
        ApiServiceProxies.SettingClientServiceProxy,
        ApiServiceProxies.ChatRoomServiceProxy,
        ApiServiceProxies.GroupMasterServiceProxy,
        ApiServiceProxies.AssetBackupTypeServiceProxy,
        ApiServiceProxies.AssetTypeServiceProxy,
        ApiServiceProxies.ComplianceLevelServiceProxy,
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.PrefixServiceProxy,
        ApiServiceProxies.StateServiceProxy,
        ApiServiceProxies.TaxRegionServiceProxy,
        ApiServiceProxies.PolicyTemplateServiceProxy,
        ApiServiceProxies.OrganizationConfigurationServiceProxy,
        ApiServiceProxies.DepartmentServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.LocationServiceProxy,
        ApiServiceProxies.RoleMSOfficeServiceProxy,
        ApiServiceProxies.ContactServiceProxy,
        ApiServiceProxies.GroupServiceProxy,
        ApiServiceProxies.ApplicationServiceProxy,
        ApiServiceProxies.IntegrationConfigurationsServiceProxy,
        ApiServiceProxies.DistributorServiceProxy,
        ApiServiceProxies.AssetServiceProxy,
        ApiServiceProxies.VendorServiceProxy,
        ApiServiceProxies.PolicyServiceProxy,
        ApiServiceProxies.PolicyRequestServiceProxy,
        ApiServiceProxies.PolicyReviewLogServiceProxy,
        ApiServiceProxies.ReminderServiceProxy,
        ApiServiceProxies.SOPTemplateServiceProxy,
        ApiServiceProxies.SOPServiceProxy,
        ApiServiceProxies.NinjaServiceProxy,
        ApiServiceProxies.ControlFamilyServiceProxy,
        ApiServiceProxies.ControlTypeServiceProxy,
        ApiServiceProxies.ControlServiceProxy,
        ApiServiceProxies.ProfileTypeServiceProxy,
        ApiServiceProxies.MdmServiceProxy,
        ApiServiceProxies.DiagramServiceProxy,
        ApiServiceProxies.FrameworkServiceProxy,
        ApiServiceProxies.ControlCampaignServiceProxy,
        ApiServiceProxies.EntityTypeServiceProxy,
        ApiServiceProxies.ControlAttentionServiceProxy,
        ApiServiceProxies.DashboardServiceProxy
    ]
})
export class ServiceProxyModule { }
