import { Component, Injector } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppComponentBase } from 'src/app/app-component-base';
import { AppConsts } from '../shared/AppConsts';
import { TenantServiceProxy } from '../shared/service-proxies/service-proxies';
import { VersionCheckService } from '../shared/services/version.check.service';
import { AppComponent } from './app.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [MessageService]
})
export class AppTopBarComponent extends AppComponentBase {

    multiTenancy: boolean = AppConsts.multiTenancy;
    versionChanged: boolean;
    notificationsList = [];
    bannerString = '';

    constructor(public app: AppComponent,
        injector: Injector,
        private tenantService: TenantServiceProxy,
        private versionCheckService: VersionCheckService,
        private confirmationService: ConfirmationService) {
        super(injector);

        this.versionCheckService.versionChanged.subscribe(x => {
            this.versionChanged = x;

            if (x) {
                this.confirmationService.confirm({
                    header: this.l('NewVersion'),
                    icon: 'pi pi-info',
                    message: this.l('NewAppVersionMessage'),
                    acceptLabel: this.l('UpdateNow'),
                    rejectLabel: this.l('Cancel'),
                    accept: () => {
                        this.updateAppVersion();
                    }
                });
            }
        });

        this.updateImageProfile();

        this.bannerText();
    }

    updateImageProfile(): void {
        this.urlPictureProfile = this.getBaseServiceUrl() + '/api/User/GetPictureProfile?id=' +
            this.app.currentUser.userId + '&v' + (new Date().getTime());
        console.log('Tenant.Name: ' + this.app.currentUser.tenantName);
    }

    updateAppVersion(): void {
        this.versionCheckService.updateVersion();
    }

    deleteNotifications(event): void {
        const self = this;

        self.notificationsList = [];
        self.app.topbarItemClick = true;

        event.preventDefault();
    }

    bannerText(): void {
        this.bannerString = this.app.currentUser.tenancyName ? this.l('Tenant')
        + ' - ' + this.app.currentUser.tenancyName : this.l('Master');

        /* if (this.bannerString === 'Master') {
            // would cause problem if partner
            if(this.app.currentUser.tenantId) {
                this.tenantService.getTenantById(this.app.currentUser.tenantId)
                    .subscribe(data => {
                        console.log('Name ' + data.name);
                    });
            } else {
                console.log('NO TENANT ID');
            }
        } else {
            console.log('NOT IN THE MASTER');
        } */
    }
}
