import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { PermissionCheckerService } from '../_services/permission.checker.service';
import { AppPermissions } from '../../shared/AppPermissions';
import { AppConsts } from '../../shared/AppConsts';
import { MultiTenancySides } from '../../shared/service-proxies/service-proxies';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private _permissionChecker: PermissionCheckerService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // verificar restricción
        if (route.data && route.data['restricted']) {
            return false;
        } else {
            // si no existe el usuario logueado, manda login
            const currentUser = this.authenticationService.currentUserValue;

            if (!currentUser) {
                this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }

            const permission = route.data['permission'];
            const multiTenancySides = route.data['multiTenancySides'];

            // si no existe el objeto data o permission en data, regresa true
            if (!route.data || (!permission && !multiTenancySides)) {
                return true;
            }

            if ((!multiTenancySides || (AppConsts.isHost && multiTenancySides === MultiTenancySides.Host)
                || (!AppConsts.isHost && multiTenancySides === MultiTenancySides.Tenant))
                && (!permission || this._permissionChecker.isGranted(permission))) {
                return true;
            }

            // not logged in so redirect to login page with the return url
            this.router.navigate([this.selectBestRoute()]);
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this.authenticationService.currentUserValue) {
            return '/account/login';
        }

        return '/app/main/dashboard';
    }
}
