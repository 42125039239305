import { AfterViewInit, Component, Input } from '@angular/core';
import { AppConsts } from '../AppConsts';
import { Utils } from '../utils/utils';
import { LocalizationService } from '../services/localization.service';
import { AppComponent } from '../../app/app.component';

@Component({
    selector: 'app-largecontenttobrief',
    templateUrl: './app.largecontenttobrief.component.html'
})
export class AppLargeContentToBriefComponent implements AfterViewInit {

    @Input() title: string;
    @Input() content: string;
    @Input() maxLengthShow: number;

    Utils = Utils;

    isOverflow = false;
    briefContent = '';
    briefContentHTML;
    seeMoreLabel = '';

    constructor(private localization: LocalizationService, private app: AppComponent) { }

    ngAfterViewInit(): void {
        const self = this;

        self.maxLengthShow = self.maxLengthShow ? self.maxLengthShow : AppConsts.largeContent.maxLengthShow;

        self.isOverflow = self.content && self.content.length > self.maxLengthShow;
        self.briefContent = self.isOverflow ? self.content.substring(0, self.maxLengthShow) : self.content;
        self.briefContentHTML = Utils.normalizeTextToHTML(self.briefContent);
        self.seeMoreLabel = self.l('SeeMore...');
    }

    l(key: string, ...args: any[]): string {
        return this.localization.l(key, ...args);
    }
}
