import { Component, Injector, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppComponentBase } from 'src/app/app-component-base';
import { StringsHelper } from '../helpers/StringsHelper';
import { Utils } from '../utils/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    templateUrl: './app.showcontent.component.html'
})
export class AppShowContentComponent extends AppComponentBase implements OnInit {

    Utils = Utils;

    content: SafeHtml;

    constructor(
        injector: Injector,
        private modalRef: DynamicDialogRef,
        public modalConfig: DynamicDialogConfig,
        private sanitized: DomSanitizer
    ) {
        super(injector);
    }

    ngOnInit() {
        const self = this;

        if (self.modalConfig.data.isHTML) {
            self.content = self.sanitized.bypassSecurityTrustHtml(self.modalConfig.data.content);
        }

        if (self.modalRef) {
            self.initModalMode();
        }
    }

    initModalMode(): void {
        const self = this;
        const templateHTML = '<button id="{0}"class="p-button p-button-secondary" type="button">{1}</button>';
        const footer = document.getElementsByClassName('p-dialog-footer')[0];
        const idButton1 = 'btn' + new Date().getMilliseconds() + (Math.random() * 100);

        footer.innerHTML = StringsHelper.formatString(templateHTML, [
            idButton1,
            self.l('Return')
        ]);

        const button = document.getElementById(idButton1);

        button.addEventListener('click', (e) => {
            self.return();
        });
    }

    return(): void {
        const self = this;

        self.modalRef.close();
    }
}
