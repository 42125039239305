import { AfterViewInit, Component, Input } from '@angular/core';

@Component({
    selector: 'app-viewcolumn-filter',
    templateUrl: './app.viewcolumnfilter.component.html'
})
export class AppViewColumnFilterComponent implements AfterViewInit {
    @Input() col: any;

    type = 'text';
    field: string;

    constructor() { }

    ngAfterViewInit(): void {
        const self = this;


    }
}
