import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DummyComponent } from './dummy.component';
import { AuthGuard } from './_guards/auth.guard';

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot([{
    path: 'app',
    component: AppComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
        {
            path: '',
            children: [
                { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' }
            ]
        },
        { path: 'dummy', component: DummyComponent },
        {
            path: 'main',
            loadChildren: () => import('../app/main/main.module').then(m => m.MainModule)
        },
        {
            path: 'admin',
            loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule)
        },
        {
            path: 'catalogs',
            loadChildren: () => import('../app/catalogs/catalogs.module').then(m => m.CatalogsModule)
        },
        {
            path: 'configurations',
            loadChildren: () => import('../app/configurations/configurations.module').then(m => m.ConfigurationsModule)
        },
        {
            path: 'office365',
            loadChildren: () => import('../app/office365/office365.module').then(m => m.Office365Module)
        },
        {
            path: 'chatrooms',
            loadChildren: () => import('../app/chatrooms/chatrooms.module').then(m => m.ChatRoomsModule)
        }, {
            path: 'integrations',
            loadChildren: () => import('../app/integrations/integrations.module').then(m => m.IntegrationsModule)
        },
        {
            path: 'policies',
            loadChildren: () => import('../app/policies/policies.module').then(m => m.PoliciesModule)
        },
        {
            path: 'sops',
            loadChildren: () => import('../app/sops/sops.module').then(m => m.SOPsModule)
        },
        {
            path: 'reminders',
            loadChildren: () => import('../app/reminders/reminders.module').then(m => m.RemindersModule)
        },
        {
            path: 'ninjaapi',
            loadChildren: () => import('../app/ninjaapi/ninja.module').then(m => m.NinjaModule)
        },
        {
            path: 'controlcampaigns',
            loadChildren: () => import('../app/controlcampaigns/controlcampaigns.module').then(m => m.ControlCampaignsModule)
        }
    ]
}]);
